<template>
  <div class="main">
    <div class="vanSwipeBlock spaceBetween">
      <div class="swipeSpan" @click="startServer">
        <div class="valueSpan">{{ currentData.RTO_NMHC }}</div>
        <div class="titleSpan">
          非甲烷总烃 <br />
          RTO1
        </div>
      </div>
      <div class="swipeSpan" @click="startServer2">
        <div class="valueSpan">{{ currentData.RTO2_NMHC }}</div>
        <div class="titleSpan">
          非甲烷总烃 <br />
          RTO2
        </div>
      </div>
      <div class="swipeSpan">
        <div class="valueSpan">{{ currentData.FUR_NOX }}</div>
        <div class="titleSpan">
          导热油炉 <br />
          NOx
        </div>
      </div>
      <div class="swipeSpan">
        <div class="valueSpan">{{ currentData.ORG_COD }}</div>
        <div class="titleSpan">
          无机废水 <br />
          COD
        </div>
      </div>
      <div class="swipeSpan">
        <div class="valueSpan">{{ currentData.INO_COD }}</div>
        <div class="titleSpan">
          有机废水 <br />
          COD
        </div>
      </div>
      <div class="swipeSpan">
        <div class="valueSpan">{{ currentData.VOCS36ID }}</div>
        <div class="titleSpan">
          大气站 <br />
          异苯丙
        </div>
      </div>
      <div class="swipeSpan">
        <div class="valueSpan">{{ toDirStr(currentData.WINDDIRECTION) }}</div>
        <div class="titleSpan">
          {{ currentData.TEMPERATURE }} <br />
          {{ currentData.WINDSPEED }}
        </div>
      </div>
      <div class="swipeSpan">
        <div class="valueSpan">
          {{ currentData.AQIList.AIR_LEVEL || "不详" }}
        </div>
        <div class="titleSpan">
          {{ currentData.AQIList.PRIMARY }} <br />
          AQI
        </div>
      </div>
    </div>
    <div class="vanSwipeBlock paddingBottom12">
      <div class="linearTitle">治理设施运行状态</div>
      <div class="contUl">
        <div class="contLi">
          <div class="leftcont">实验室活性炭吸附装置</div>
          <div class="rightcont" style="color: #16C969;background: #DAF7E8;">
            运行中
          </div>
        </div>
        <div class="contLi">
          <div class="leftcont">洗涤塔</div>
          <div class="rightcont" style="color: #EE9D00;background: #FFF4DF;">
            故障
          </div>
        </div>
        <div class="contLi">
          <div class="leftcont">导热油炉</div>
          <div class="rightcont" style="color: #FF3434;background: #FFE1E1;">
            停运
          </div>
        </div>
        <div class="contLi">
          <div class="leftcont">RTO-U4401</div>
          <div class="rightcont" style="color: #16C969;background: #DAF7E8;">
            运行中
          </div>
        </div>
        <div class="contLi">
          <div class="leftcont">无机废水池-AD8410</div>
          <div class="rightcont" style="color: #16C969;background: #DAF7E8;">
            运行中
          </div>
        </div>
        <div class="contLi">
          <div class="leftcont">废水中和池-U8408</div>
          <div class="rightcont" style="color: #16C969;background: #DAF7E8;">
            运行中
          </div>
        </div>
      </div>
    </div>
    <div class="vanSwipeBlock">
      <div class="linearTitle">环境监控标志灯</div>
      <div class="contUl">
        <div
          class="contLi"
          v-for="(items, index) in outletStatusList"
          :key="index"
        >
          <div class="leftcont">{{ items.OUTLET_NAME }}</div>
          <div
            class="rightcont"
            :style="{
              color:
                items.OUTLETSTATUS == 0
                  ? '#16C969'
                  : items.OUTLETSTATUS == 1
                  ? '#949494'
                  : items.OUTLETSTATUS == 2
                  ? '#949494'
                  : items.OUTLETSTATUS == 3
                  ? '#949494'
                  : items.OUTLETSTATUS == 4
                  ? '#EE9D00'
                  : items.OUTLETSTATUS == 5
                  ? '#FF3434'
                  : '',
              border:
                items.OUTLETSTATUS == 0
                  ? '1px solid #48D88B'
                  : items.OUTLETSTATUS == 1
                  ? '1px solid #B4B4B4'
                  : items.OUTLETSTATUS == 2
                  ? '1px solid #B4B4B4'
                  : items.OUTLETSTATUS == 3
                  ? '1px solid #B4B4B4'
                  : items.OUTLETSTATUS == 4
                  ? '1px solid #FFC95F'
                  : items.OUTLETSTATUS == 5
                  ? '1px solid #FF6868'
                  : ''
            }"
          >
            {{
              items.OUTLETSTATUS == 0
                ? "正常"
                : items.OUTLETSTATUS == 1
                ? "校准"
                : items.OUTLETSTATUS == 2
                ? "维护"
                : items.OUTLETSTATUS == 3
                ? "离线"
                : items.OUTLETSTATUS == 4
                ? "预警"
                : items.OUTLETSTATUS == 5
                ? "报警"
                : ""
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import Http from "../../../api/http";
export default {
  setup() {
    const currentData = reactive({
      FUR_NOX: 0,
      INO_COD: 0,
      ORG_COD: 0,
      RTO2_NMHC: 0,
      RTO_NMHC: 0,
      WINDSPEED: 0,
      WINDDIRECTION: 0,
      VOCS36ID: 0,
      TEMPERATURE: 0,
      AQIList: { AIR_LEVEL: "不详" }
    });
    const outletStatusList = ref([]);

    const getoutstatus = () => {
      Http.get(
        {
          method: "queryProcedure",
          procedure: "SP_HOME_OULTET_STATUS",
          procedureParams: "",
          procedureResult: "list1"
        },
        false,
        true,
        true
      )
        .then(res => {
          var list = res.list1;
          outletStatusList.value = list;
        })
        .catch(() => {});
    };
    const getData = () => {
      Http.get(
        {
          method: "queryProcedure",
          procedure: "SP_HOME_REPORT_SUMMARY",
          procedureParams: "${token_user_id}",
          procedureResult: "list1|list2|list3|list4|list5"
        },
        false,
        true,
        true
      )
        .then(res => {
          var list1 = res.list1,
            list2 = res.list2,
            list3 = res.list3;
          currentData.FUR_NOX = list1[0].FUR_NOX;
          currentData.INO_COD = list1[0].INO_COD;
          currentData.ORG_COD = list1[0].ORG_COD;
          currentData.RTO2_NMHC = list1[0].RTO2_NMHC;
          currentData.RTO_NMHC = list1[0].RTO_NMHC;
          currentData.WINDSPEED = list3[0].WINDSPEED;
          currentData.WINDDIRECTION = list3[0].WINDDIRECTION;
          currentData.VOCS36ID = list3[0].VOCS36ID;
          currentData.TEMPERATURE = list3[0].TEMPERATURE;
          currentData.AQIList =
            list2.length > 0 ? list2[0] : { AIR_LEVEL: "不详" };
        })
        .catch(() => {});
    };
    const toDirStr = val => {
      return Http.toDirStr(val);
    };
    // 这是启动服务
    const startServer = () => {
      window.rfidPlugin.ReadRfidStart(
        null,
        function() {},
        function(err) {
          alert("调用失败");
          alert(err.toString());
        }
      );
    };
    const getRFID = data => {
      alert("EPC" + data.strEPC);
      // n.value = n.value + 1;
      // 大于等于10次的时候停止
      // if(n.value >=10) {
      //     window.rfidPlugin.StopRfidService(null,function() {},function() {});
      // }
      // alert('PC'+data.strPC+'EPC'+data.strEPC+'RSSI'+data.strRSSI+'Freq'+data.strFreq+'readCount'+data.nReadCount+'date'+data.mDate);
    };
    onMounted(() => {
      window.getRFID = getRFID;
      getData();
      getoutstatus();
    });
    return {
      currentData,
      outletStatusList,
      toDirStr,
      startServer,
      getRFID
    };
  }
};
</script>
